<template>
  <div id="cobra-resources-subview">
    <div class="prose prose-md md:prose-lg">
      <h1>COBRA Resources</h1>
      <p>
        We want to make your initial setup as smooth and easy as we can. The
        information below will help you with the process. Are you ready?
      </p>
    </div>
    <h2 class="mt-8 mb-4 text-3xl font-bold text-primary">What To Send Us</h2>
    <div
      class="p-4 mb-4 bg-neutral-light border border-neutral border-solid rounded-lg"
    >
      <div class="flex flex-row flex-wrap items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-clipboard-1"></span>
        <h3 class="flex-1 text-xl font-semibold">
          Qualified Beneficiary Census
        </h3>
        <Button
          class="w-full mx-2 my-2 sm:w-auto sm:my-0"
          sml
          trailing="flaticon-down"
          href="/QB Import Template.xlsx"
          download
        >
          Download Here
        </Button>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            QB Templates are generally used at the time of a group's setup. They
            can also be used to report a qualifying event to RMR if a group
            isn't comfortable with submitting entries through the Administration
            Portal.
          </li>
          <li>
            Starting from the left and moving right, you would need to have the
            information completed for the given Qualified Beneficiary and their
            dependents.
          </li>
          <li>
            Once returned to our office, these can either be entered as one-offs
            or by import.
          </li>
        </ul>
      </div>
    </div>
    <div class="p-4 mb-4 border border-neutral border-solid rounded-lg">
      <div class="flex flex-row flex-wrap items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-spreadsheet"></span>
        <h3 class="flex-1 text-xl font-semibold">NPM Template</h3>
        <Button
          class="w-full mx-2 my-2 sm:w-auto sm:my-0"
          sml
          trailing="flaticon-down"
          href="/NPM Import Template.xlsx"
          download
        >
          Download Here
        </Button>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            NPM Templates are used at the time of a group's setup or to capture
            a group's active-employee enrollment after Open Enrollment periods
            end.
          </li>
          <li>
            Starting from the left and moving to the right, you would indicate
            the active-employees information.
            <ul>
              <li>We do not capture dependents on this import/template.</li>
            </ul>
          </li>
          <li>
            Once returned to our office, the information can be easily imported.
          </li>
        </ul>
      </div>
    </div>
    <div
      class="p-4 mb-4 bg-neutral-light border border-neutral border-solid rounded-lg"
    >
      <div class="flex flex-row flex-wrap items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-files"></span>
        <h3 class="flex-1 text-xl font-semibold">ACH Authorization Form</h3>
        <Button
          class="w-full mx-2 my-2 sm:w-auto sm:my-0"
          sml
          trailing="flaticon-down"
          href="/RMR ACH Authorization Form.pdf"
          download
        >
          Download Here
        </Button>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            This will authorize RMR to pull funds for plan funding and monthly
            administrative invoices.
          </li>
          <li>
            Download to fill out and send it to finance@rmrbenefits.com and your
            account manager.
          </li>
        </ul>
      </div>
    </div>
    <div class="p-4 mb-4 border border-neutral border-solid rounded-lg">
      <div class="flex flex-row items-center mb-4">
        <span class="inline-block mr-4 text-5xl flaticon-file"></span>
        <h3 class="flex-1 text-xl font-semibold">Plan and Rate Information</h3>
        <Button
          class="w-full mx-2 my-2 sm:w-auto sm:my-0"
          sml
          trailing="flaticon-down"
          href="/COBRA Plan and Rate Information.xlsx"
          download
        >
          Download Here
        </Button>
      </div>
      <div class="max-w-full prose-sm prose">
        <ul>
          <li>
            This information is needed so RMR can invoice participants for their
            monthly COBRA premiums.
          </li>
          <li>
            We ask the group to complete the template with each carrier and plan
            description names that they offer along with the "base" carrier
            premium associated with the different coverage levels that the group
            offers.
          </li>
          <li>
            RMR takes this information and loads it into the system for
            notification and invoicing purposes.
          </li>
        </ul>
      </div>
    </div>
    <h2 class="mt-12 mb-4 text-3xl font-semibold text-primary">
      COBRA Materials
    </h2>
    <div
      class="overflow-hidden border border-neutral border-solid divide-x-0 divide-y divide-neutral rounded-lg divide-solid"
    >
      <a
        :href="
          require('@/assets/forms/COBRApoint Instructions for Employers.pdf')
        "
        download
        class="relative flex flex-row p-4 transition-all hover:bg-primary-mid hover:text-white active:bg-primary"
      >
        <span class="inline-block mx-0 my-auto text-5xl flaticon-pdf" />
        <div class="flex flex-col flex-1 m-0 ml-4 text-left">
          <h4 class="m-0">
            How to Add New Hires, Terminate Employees, and Run Remittance
          </h4>
          <p class="m-0 text-sm">
            This booklet explains how to add employees to the COBRApoint system.
          </p>
        </div>
      </a>
      <a
        :href="require('@/assets/forms/Employee Guide to COBRA.pdf')"
        download
        class="relative flex flex-row p-4 transition-all hover:bg-primary-mid hover:text-white active:bg-primary"
      >
        <span class="inline-block mx-0 my-auto text-5xl flaticon-pdf" />
        <div class="flex flex-col flex-1 m-0 ml-4 text-left">
          <h4 class="m-0">COBRA Employee Guide</h4>
          <p class="m-0 text-sm">
            This booklet explains employee rights under COBRA to a temporary
            extension of employer-provided group health coverage, called COBRA
            continuation coverage.
          </p>
        </div>
      </a>

      <router-link
        to="/support/sections/360003792274"
        class="relative flex flex-row p-4 transition-all hover:bg-primary-mid hover:text-white active:bg-primary"
      >
        <span class="inline-block mx-0 my-auto text-5xl flaticon-question-1" />
        <div class="flex flex-col flex-1 m-0 ml-4 text-left">
          <h4 class="m-0">COBRA Support Articles</h4>
          <p class="m-0 text-sm">
            Our COBRA support articles contain answers to frequently asked
            questions and information on many other topics. A good resource for
            employers and former employees alike.
          </p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  },
  data() {
    return {
      componentName: "Cobra Resources"
    };
  }
};
</script>
